import { graphql } from "gatsby"
import * as React from "react"
import Contact from "../components/contact"
import Layout from "../components/layout"
import Seo from "../components/seo"
const logo = require("../images/whitelogo.svg").default

const ContactPage = props => (
  <Layout
    backgroundColor="#000000"
    logo={logo}
    menuBGColor="#000000"
    hamburgerColor="white"
    footerColor="#000000"
  >
    <Seo title="Rajyashree Ramesh" />
    <Contact data={props.data} />
  </Layout>
)

export default ContactPage

export const pageQuery = graphql`
  query {
    allFile(
      filter: {
        relativeDirectory: { eq: "MenuImages" }
        name: { eq: "contact" }
      }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG)
          id
        }
      }
    }
  }
`
