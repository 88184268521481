import styled from "@emotion/styled"

export const FlexBox = styled.div`
  display: flex;
  justify-content:space-around;
  align-items:center;
  background-color: #000000;
  width:100%;
  .form-container{
    width:40%;
    @media (max-width: 768px) {
          width: 90%;
          margin:auto;
       }
  }
`
export const FormControl = styled.div`
  width: 100%;
  padding: 40px 25px;

  input,
  textarea {
    width: 100%;
    background: #000000;
    font-family: "Marcellus";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
    padding-bottom:6px;
    
    &::placeholder {
      color: #ffffff;
    }
  }

  textarea {
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid #ffffff;
    outline:none;
    resize: none;
  }

  input {
    border: none;
    outline: none;
    border-bottom: 1px solid #ffffff;
  }
  label {
    font-family: Marcellus;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    color: #ffffff;
  }
`

export const ImgContainer = styled.div`
  width: 45%; 
  height: auto;
  @media (max-width: 768px) {
    display: none;
  }
`

export const Button = styled.button`
  float: right;
  width: 120px;
  height: 44px;
  margin-right:25px;
  outline: none;
  background-color:#ffffff;
  color: #8F8F8F;
  border:none;

  &:hover {
    cursor: pointer;
  }
`
