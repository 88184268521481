import React  from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Button, FlexBox, FormControl, ImgContainer } from "./styled"
import { PosterTitle } from "../commons/PosterContainer.style"

import { ImageFadeIn } from "../commons/HighlightsCards.style"
import { PageDescriptionContainer } from "../practitioner/practitionerstyles"

import { 
  PosterImageContainer,
} from "../commons/PosterContainer.style"
const Contact = ({ data }) => {
  console.log(data.allFile)
  return (
    <FlexBox>
      <form className="form-container">
        <PosterTitle>CONTACT</PosterTitle>
        <FormControl>
          <input type="text" placeholder="Full Name" />
        </FormControl>
        <FormControl>
          <input type="text" placeholder="Email" />
        </FormControl>
        <FormControl>
          <label htmlFor="message">Your Message</label>
          <textarea name="message" id="message" cols={30} rows={7} />
        </FormControl>
        <Button type="button">SUBMIT</Button>
      </form>
      <ImgContainer>
        <PageDescriptionContainer>
          <PosterImageContainer width="1020px">
            <ImageFadeIn>
              <StaticImage
                style={{ opacity: 0.7 ,height: 'inherit', width: 'inherit' }}
                src="../../images/MenuImages/contact1.png"
                alt="core concepts"
                placeholder="dominantColor"
                as="div"
              />
            </ImageFadeIn>
          </PosterImageContainer>
        </PageDescriptionContainer>
      </ImgContainer>
    </FlexBox>
  )
}

export default Contact
